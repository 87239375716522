import { Injectable } from '@angular/core';
import { Validators, FormArray } from '@angular/forms';

import { ApplicationConfig } from 'src/config/app.config';
import {
  phoneValidator,
  alphaNumericValidator,
  percentValidator,
  textValidator,
  zipValidator,
  emailValidator,
  dobValidator,
  currencyValidator,
  ssnValidator,
  fileValidator,
  routingAccountNoValidator,
  ssnLastFourValidator,
} from '../validators/validator';
import { UtilsService } from 'src/app/shared/services/utils.service';
import { Question } from '../models/casePage.model';
import { AgentInfoConstant, AgentInfoContConstant, TIAInfoConstant } from 'src/config/constants';
import {
  advancePremiumAmountValidator,
  attestValidator,
  checkForAlphaNumeric,
  checkForGreaterThanZero,
  checkForNoOfDigits
} from '../validators/business-validator';
import { UserDetailsService } from './user-details.service';

@Injectable({
  providedIn: 'root',
})
export class FormGeneratorService {
  constructor(
    private appConfig: ApplicationConfig,
    public utilsService: UtilsService,
    private userDetailsService: UserDetailsService
  ) { }

  gridQuestionsData = {};

  getdropdownOptions(questionData): any {
    let alldropDownOptions = {};
    for (const data of questionData) {
      alldropDownOptions = this.dropdownOptionsForLoop(
        data,
        alldropDownOptions
      );
    }
    return alldropDownOptions;
  }

  dropdownOptionsForLoop(data, alldropDownOptions): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      alldropDownOptions[data.fieldId] = this.utilsService.formatOptions(
        data.questionOption
      );
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        alldropDownOptions = this.dropdownOptionsForLoop(
          childData,
          alldropDownOptions
        );
      }
    }
    return alldropDownOptions;
  }
  // Dynamic Forms creations
  createFormControls(questionsData): any {
    let form = {};
    for (const data of questionsData) {
      form = this.createFormForallLoops(data, form);
    }
    return form;
  }

  setGridQuestionsData(gridQuestionsData): void {
    if (this.gridQuestionsData) {
      Object.keys(gridQuestionsData).forEach(gridId => {
        this.gridQuestionsData[gridId] = gridQuestionsData[gridId];
      });
    } else {
      this.gridQuestionsData = gridQuestionsData;
    }

  }

  createFormForallLoops(data, form, answerData?): any {
    if (data.controlTypeDesc === this.appConfig.fieldTypes.GRID) {
      // Do Nothing
    } else {
      if (answerData && !(data.controlTypeDesc === this.appConfig.fieldTypes.TEXT && data.subText === 'File')) {
        data.question_answer = answerData[data.fieldId];
      }
      form = this.createControl(data, form);
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        form = this.createFormForallLoops(childData, form, answerData);
      }
    }
    return form;
  }

  createControl(data, form): any {
    let answer = this.getAnswer(data);
    const ft = this.appConfig.fieldTypes;
    if (data.controlTypeDesc === ft.HDR) {
      // do nothing
    } else if (data.controlTypeDesc === ft.SSN) {
      this.updateValidatorForSSNFieldTypes(data, form, answer);
    } else if (data.controlTypeDesc === ft.PHONE) {
      answer = this.utilsService.phoneNumberFormat(answer, true);
      form[data.fieldId] = [answer, [phoneValidator()]];
    } else if (data.controlTypeDesc === ft.ALPHANUMERIC) {
      form[data.fieldId] = [answer, [alphaNumericValidator()]];
    } else if (data.controlTypeDesc === ft.RADIO) {
      this.updateRadioValidator(form, data, answer);
    } else if (data.controlTypeDesc === ft.TEXT) {
      this.updateFormValidatorBasedOnCondition(form, data, answer, ft);
    } else if (data.controlTypeDesc === ft.CURRENCY && data.notes === 'ITES_COVERAGE_VALIDATOR') {
      form[data.fieldId] = [answer, [currencyValidator()]];
    } else if (data.controlTypeDesc === ft.TEXTAREA) {
      form[data.fieldId] = [answer, []];
    } else if (data.controlTypeDesc === ft.EMAIL) {
      form[data.fieldId] = [answer, [emailValidator()]];
    } else if (data.controlTypeDesc === ft.DOB) {
      this.getAnswerForDOBFieldTypes(answer, data, form);
    } else {
      answer = this.getAnswerForOtherFieldTypes(data, ft, answer);
      this.updateValidatorForOtherFieldsTypes(data, ft, answer, form);
    }
    return form;
  }

  private updateValidatorForSSNFieldTypes(data, form, answer): void {
    if (data.subText === 'lastFourDigitSSN') {
      form[data.fieldId] = [answer, [ssnLastFourValidator()]];
    } else if (data.xmlTag === AgentInfoConstant.agentNPNQuesId || data.xmlTag === AgentInfoConstant.secAgentNPN) {
      form[data.fieldId] = [answer, []];
    } else {
      form[data.fieldId] = [answer, [ssnValidator()]];
    }
  }

  private updateValidatorForOtherFieldsTypes(data, ft, answer, form): void {
    form[data.fieldId] = [answer];
  }

  private updateRadioValidator(form, data, answer): void {
    if (data.xmlTag === AgentInfoConstant.doYouattestTag || data.xmlTag === AgentInfoContConstant.knowEnglishQuesId
      || data.xmlTag === AgentInfoContConstant.caseManagerDoYouAttestQuesId
      || data.xmlTag === AgentInfoContConstant.officeStaffDoYouAttestQuesId) {
      form[data.fieldId] = [answer, [attestValidator()]];
    } else {
      form[data.fieldId] = [answer];
    }
  }

  getAnswerToPopulateDropDown(options, answer): any {
    let ans = {};
    for (const val of options) {
      if (val.value === answer) {
        ans = {
          value: val.value,
          label: val.description,
        };
        break;
      }
    }
    return ans;
  }

  clearAnswersIfHidden(data, value, uncheckedValue?): any {
    if (data && data.hasReflexive === 'true') {
      let updatedVal = {};
      for (const childData of data.reflexiveQuestionAL) {
        const formValue = value[data.fieldId];
        const answer =
          data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && formValue
            ? formValue.value
            : formValue;
        const hideChildren = this.isChildrenHide(
          childData,
          answer,
          data,
          uncheckedValue
        );
        updatedVal = this.getUpdatedValue(
          hideChildren,
          updatedVal,
          childData,
          data
        );
      }
      return updatedVal;
    }
    return null;
  }

  clearAnswersForAllLoops(data, updatedVal): any {
    const ft = this.appConfig.fieldTypes;
    const nonFormElements = [ft.HDR];
    if (data.controlTypeDesc === ft.GRID) {
      updatedVal[data.gridId] = null;
    } else if (nonFormElements.indexOf(data.controlTypeDesc) === -1) {
      updatedVal[data.fieldId] = '';
    }
    if (data && data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        updatedVal = this.clearAnswersForAllLoops(childData, updatedVal);
      }
    }
    return updatedVal;
  }

  updateAnswersForAllLoops(componentData, parentAnswer?): any {
    const ft = this.appConfig.fieldTypes;
    const nonFormElements = [ft.HDR, ft.GRID];
    const data = componentData.data;
    const value = componentData.form.getRawValue();
    if ((nonFormElements.indexOf(data.controlTypeDesc) === -1) && data.display) {
      data.question_answer = this.updateAnswers(data, value);
      const inputVisible = this.checkIfInputVisible(parentAnswer, data);
      componentData = this.getStatusOfForm(inputVisible, componentData);
    } else if (data.controlTypeDesc === ft.GRID) {
      // Do Nothing
    }
    if (data.hasReflexive === 'true') {
      this.updateDataForReflexiveQuestions(data, componentData, ft);
    }
    componentData.data = data;
    return componentData;
  }

  getStatusOfForm(inputVisible, componentData): any {
    const data = componentData.data;
    const form = componentData.form;
    if (inputVisible) {
      if ((data.controlTypeDesc === this.appConfig.fieldTypes.TEXT && data.subText === 'File'
        && form.controls[data.fieldId].invalid && !data.question_answer)
        || (data.controlTypeDesc === this.appConfig.fieldTypes.TEXT && data.subText !== 'File'
          && form.controls[data.fieldId].invalid)
        || (data.controlTypeDesc !== this.appConfig.fieldTypes.HDR && data.controlTypeDesc !== this.appConfig.fieldTypes.TEXT
          && form.controls[data.fieldId].invalid)) {
        componentData.formValid = false;
      }
    }

    if (form.controls[data.fieldId].errors && form.value[data.fieldId]) {
      componentData.formHasNoErrors = false;
    }
    return componentData;
  }

  nestedQuestionsDisplayed(data, answer): any {
    let nestedQuestionDisplayed = false;
    for (const childL1 of data.reflexiveQuestionAL) {
      // tslint:disable-next-line: max-line-length
      let condition = null;
      if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
        const optionChoice = childL1.optionChoice
          ? childL1.optionChoice.toString()
          : '';
        condition = answer && optionChoice.indexOf(answer.value) >= 0;
      } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
        const optionChoice = childL1.optionChoice === 'Yes{47}Yes';
        condition = answer === optionChoice;
      } else {
        condition = answer?.toString() === childL1.optionChoice;
      }
      if (condition) {
        nestedQuestionDisplayed = true;
        break;
      }
    }
    return nestedQuestionDisplayed;
  }

  updateAnswers(dataObj, formValue): any {
    let answerToReturn = null;
    answerToReturn = this.updateAnswerBasedOnControlType(
      answerToReturn,
      dataObj,
      formValue
    );
    answerToReturn = this.updateAnswerBasedOnAnswerTypeText(
      answerToReturn,
      dataObj,
      formValue
    );
    if (dataObj.controlTypeDesc === this.appConfig.fieldTypes.TEXT && dataObj.subText === 'File') {
      answerToReturn = dataObj.question_answer;
    }
    if (answerToReturn == null) {
      answerToReturn = this.utilsService.formatNamesforSpace(
        formValue[dataObj.fieldId]
      );
    }
    return answerToReturn;
  }

  private updateAnswerBasedOnAnswerTypeText(
    answerToReturn: any,
    dataObj: any,
    formValue: any
  ): any {
    if (dataObj.answerTextType === 'Numeric only') {
      const answerVal = formValue[dataObj.fieldId];
      answerToReturn =
        answerVal && answerVal !== null
          ? answerVal.replace(/[^0-9.]/g, '')
          : answerVal;
      answerToReturn = dataObj.notes?.indexOf('ITES_PERCENTAGE') > -1 && +answerToReturn > 100 ? '100' : answerToReturn;
      answerToReturn =
        answerVal && answerVal !== null && answerToReturn === ''
          ? '0'
          : answerToReturn;
    }
    return answerToReturn;
  }

  private updateAnswerBasedOnControlType(
    answerToReturn: any,
    dataObj: any,
    formValue: any
  ): any {
    if (dataObj.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      answerToReturn = this.checkForNotes(dataObj, formValue, false);
    } else if (dataObj.controlTypeDesc === this.appConfig.fieldTypes.PHONE) {
      const phone = formValue[dataObj.fieldId];
      answerToReturn = this.utilsService.phoneNumberFormat(phone, false);
    } else if (dataObj.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answerToReturn = this.checkForNotes(dataObj, formValue, true);
    } else if (dataObj.controlTypeDesc === this.appConfig.fieldTypes.DOB) {
      answerToReturn = this.utilsService.formatDate(formValue[dataObj.fieldId]);
    }
    return answerToReturn;
  }

  private checkForNotes(
    dataObj: any,
    formValue: any,
    isCheckbox: boolean
  ): any {
    let answerToReturn;
    if (isCheckbox) {
      answerToReturn = this.updateAnswerForCheckbox(dataObj, formValue);
    } else {
      answerToReturn = this.updateAnswerForSelect(dataObj, formValue);
    }
    return answerToReturn;
  }

  private updateAnswerForCheckbox(dataObj: any, formValue: any): string {
    let answerToReturn = '';
    if (
      formValue[dataObj.fieldId] !== '' ||
      formValue[dataObj.fieldId] !== null
    ) {
      // check if checkbox value as false
      if (
        formValue[dataObj.fieldId] &&
        (formValue[dataObj.fieldId] === true ||
          formValue[dataObj.fieldId].indexOf('Yes') > -1)
      ) {
        answerToReturn = dataObj.questionOption[0].value;
      }
    }
    return answerToReturn;
  }

  private updateAnswerForSelect(dataObj, formValue): string {
    let answer = formValue[dataObj.fieldId];
    if (answer !== null && answer !== undefined && typeof answer !== 'string') {
      answer = answer.value;
      if (!answer) {
        answer = '';
      }
    }
    return answer;
  }

  private checkIfInputVisible(parentAnswer: any, data: any): boolean {
    let inputVisible = true;
    if (typeof parentAnswer !== 'undefined') {
      const optionChoice = data.optionChoice || '';
      if (
        parentAnswer != null &&
        typeof parentAnswer === 'string' &&
        parentAnswer.indexOf(';') >= 0
      ) {
        inputVisible = parentAnswer.indexOf(optionChoice) >= 0;
      } else {
        inputVisible =
          parentAnswer !== '' ? optionChoice.indexOf(parentAnswer) >= 0 : false;
      }
    }
    return inputVisible;
  }

  private updateDataForReflexiveQuestions(
    data: any,
    componentData: any,
    ft: any
  ): void {
    for (const childData of data.reflexiveQuestionAL) {
      let answer = componentData.form.get(data.fieldId).value;
      answer = this.getAnswerIfDropdown(answer, data, ft);
      // checking if control type of question is checkbox and answer is true or checked set value from option
      if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
        answer = answer === true ? data.questionOption[0].value : answer;
      }
      componentData.data = childData;
      componentData = this.updateAnswersForAllLoops(componentData, answer);
    }
  }

  private getAnswerIfDropdown(answer, data, ft): any {
    return data.controlTypeDesc === ft.SELECT && answer ? answer.value : answer;
  }

  private isChildrenHide(childData, answer, data, uncheckedValue): boolean {
    let hideChildren: boolean;
    if (uncheckedValue) {
      /* Hiding unchecked checkbox child question.  */
      hideChildren = childData.optionChoice.indexOf(uncheckedValue) > -1;
    } else {
      // check if control type as checkbox
      if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
        // if answer is true, don't hide children if there
        if (answer) {
          hideChildren = false;
        } else {
          hideChildren = childData.optionChoice.indexOf(answer) === -1;
        }
      } else {
        hideChildren = childData.optionChoice.indexOf(answer) === -1;
      }
    }
    return hideChildren;
  }

  private getUpdatedValue(
    hideChildren: boolean,
    updatedVal: any,
    childData: any,
    data: any
  ): any {
    if (hideChildren) {
      updatedVal = this.clearAnswersForAllLoops(childData, updatedVal);
    } else {
      // if control type as checkbox update the answer to blank and value of checkbox is true
      if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
        updatedVal = this.clearAnswersForAllLoops(childData, updatedVal);
        updatedVal[childData.fieldId] = '';
      }
    }
    return updatedVal;
  }

  private getAnswerForOtherFieldTypes(data: any, ft: any, answer: any): any {
    if (data.controlTypeDesc === ft.CHECKBOX) {
      let answerFlag = false;
      if (answer && answer !== '') {
        answerFlag = true;
      }
      answer = answerFlag;
    }
    if (
      data.controlTypeDesc === ft.SELECT &&
      answer !== '' &&
      answer !== null &&
      typeof answer === 'string'
    ) {
      answer = this.getAnswerToPopulateDropDown(data.questionOption, answer);
    }
    return answer;
  }

  private getAnswerForDOBFieldTypes(answer: any, data: any, form: any): void {
    answer = this.utilsService.formatDate(answer);
    const format = 'MMDDYYYY';
    if (data.required === 'true') {
      form[data.fieldId] = [
        answer,
        [Validators.required, dobValidator(format)],
      ];
    } else {
      form[data.fieldId] = [answer, [dobValidator(format)]];
    }
  }

  private updateFormValidatorBasedOnCondition(
    form: any,
    data: any,
    answer: any,
    ft: any
  ): void {
    const validatorBasedOnXmlTag = [AgentInfoConstant.allStateAgentIdXmlTag, AgentInfoConstant.clientAccountNoXmlTag,
    TIAInfoConstant.advancedPremiumAmount];
    if (data.subText === 'File') {
      form[data.fieldId] = ['', fileValidator()];
    } else if (data.notes && data.notes.indexOf('ITES_FORMAT_AS_PERCENTAGE') !== -1) {
      form[data.fieldId] = [answer, [percentValidator()]];
    } else if (validatorBasedOnXmlTag.includes(data.xmlTag)) {
      this.updateValidatorBasedOnXmlTag(form, data, answer);
    } else if (data.xmlTag === AgentInfoConstant.splitMainAgentQuesId ||
      data.fieldId === AgentInfoConstant.secSplitQuesId) {
      form[data.fieldId] = [answer, [checkForGreaterThanZero()]];
    } else if (!data.notes && data.answerTextType !== 'Numeric only') {
      form[data.fieldId] = [answer, [textValidator()]];
    } else if (data.notes === 'ITES_FORMAT_AS_ZIPCODE') {
      form[data.fieldId] = [answer, [zipValidator()]];
    } else if (data.notes && data.notes.indexOf('ITES_COVERAGE_VALIDATOR') > -1
      && data.notes.indexOf('ITES_CURRENCY_USD') !== -1) {
      form[data.fieldId] = [answer, [currencyValidator()]];
    } else if (data.notes && data.notes.indexOf('ROUTING_ACCOUNT_NUMBER') > -1) {
      form[data.fieldId] = [answer, [routingAccountNoValidator()]];
    } else {
      answer = this.getAnswerForOtherFieldTypes(data, ft, answer);
      form[data.fieldId] = [answer];
    }
  }

  private updateValidatorBasedOnXmlTag(form: any, data: any, answer: any): void {
    if (data.xmlTag === AgentInfoConstant.allStateAgentIdXmlTag) {
      form[data.fieldId] = [answer, [checkForNoOfDigits(5, 10)]];
    } else if (data.xmlTag === AgentInfoConstant.clientAccountNoXmlTag) {
      form[data.fieldId] = [answer, [checkForAlphaNumeric()]];
    } else if (data.xmlTag === TIAInfoConstant.advancedPremiumAmount) {
      form[data.fieldId] = [answer, [advancePremiumAmountValidator()]];
    }
  }

  private getAnswer(data: any): any {
    return data.question_answer || '';
  }

  createGrid(_gridId, gridQuestion: Question[], gridAnswer?): any {
    gridQuestion = JSON.parse(JSON.stringify(gridQuestion));
    let gridform = {};
    // creating grid control
    for (const gridColumn of gridQuestion) {
      gridform = this.createFormForallLoops(gridColumn, gridform, gridAnswer);
    }
    return gridform;
  }

  updateGridAnswerArray(gridId, gridQuestionsData, gridAnswer): any {
    if (gridAnswer) {
      const gridQuestion = gridQuestionsData[gridId].gridQuestions;
      let modifiedGridAnswerObj = {};
      modifiedGridAnswerObj = this.convertGridAnswersToFieldIDFormat(
        gridQuestion,
        gridAnswer,
        modifiedGridAnswerObj
      );
      return modifiedGridAnswerObj;
    } else {
      return [];
    }
  }

  convertGridAnswersToFieldIDFormat(
    question,
    gridAnswer,
    modifiedGridAnswerObj
  ): any {
    this.updatemodifiedGridAnswerObj(gridAnswer, modifiedGridAnswerObj, question);
    for (const quest of question) {
      if (quest && quest.hasReflexive === 'true') {
        modifiedGridAnswerObj = this.convertGridAnswersToFieldIDFormat(
          quest.reflexiveQuestionAL,
          gridAnswer,
          modifiedGridAnswerObj
        );
      }
    }
    return modifiedGridAnswerObj;
  }

  private updatemodifiedGridAnswerObj(gridAnswer, modifiedGridAnswerObj, question): void {
    for (const obj in gridAnswer) {
      if (obj) {
        const keyObj = question.find((ele) => ele.name === obj);
        if (keyObj) {
          this.getModifiedAnswerBasedOnControlType(keyObj, modifiedGridAnswerObj, gridAnswer, obj);
        }
      }
    }
  }

  private getModifiedAnswerBasedOnControlType(keyObj: Question, modifiedGridAnswerObj: any, gridAnswer: any[], obj: any): void {
    if (keyObj.controlTypeDesc === this.appConfig.fieldTypes.SELECT) {
      gridAnswer[obj] = gridAnswer[obj] ? gridAnswer[obj] : keyObj.question_answer;
      const ans = this.utilsService.getAnswerMatchingWithDropDownOption(
        gridAnswer[obj],
        keyObj.questionOption
      );
      modifiedGridAnswerObj[keyObj.fieldId] = ans;
    } else {
      modifiedGridAnswerObj[keyObj.fieldId] = gridAnswer[obj];
    }
  }

  checkGridStatusAndGetGridData(componentData, gridId): any {
    const form = componentData.form;
    const gridQuestion = this.gridQuestionsData[gridId].gridQuestions;
    const formArray = form.get(gridId) as FormArray;
    const gridData = [];
    const gridDataWithNameTag = [];
    for (let j = 0; j < formArray.length; j++) {
      const formGrid = formArray.at(j);
      componentData.answers = {};
      componentData.answersWithNameTag = {};
      for (const gridColumn of gridQuestion) {
        componentData.data = JSON.parse(JSON.stringify(gridColumn));
        componentData.form = formGrid;
        componentData = this.updateGridAnswers(componentData);
      }
      gridData[j] = componentData.answers;
      gridDataWithNameTag[j] = componentData.answersWithNameTag;
    }
    componentData.gridData[gridId] = gridData;
    componentData.gridDataWithNameTag[gridId] = gridDataWithNameTag;
    return componentData;
  }

  updateGridAnswers(componentData, parentAnswer?): any {
    const ft = this.appConfig.fieldTypes;
    const nonFormElements = [ft.HDR, ft.GRID, ft.USAADDRESS];
    const data = componentData.data;
    const form = componentData.form;
    const value = form.getRawValue();
    if (nonFormElements.indexOf(data.controlTypeDesc) === -1) {
      const ans = this.updateAnswers(data, value);
      componentData.answers[data.fieldId] = ans;
      componentData.answersWithNameTag[data.name] = ans;
      let inputVisible = true;
      if (typeof parentAnswer !== 'undefined') {
        const optionChoice = data.optionChoice || '';
        inputVisible = optionChoice.indexOf(parentAnswer) >= 0;
      }
      componentData = this.getStatusOfForm(inputVisible, componentData);
    }
    if (data.hasReflexive === 'true') {
      for (const childData of data.reflexiveQuestionAL) {
        if (componentData.answers[data.fieldId] === childData.optionChoice) {
          const answer = this.getAnswerFromForm(form, data);
          componentData.data = childData;
          componentData = this.updateGridAnswers(componentData, answer);
        }
      }
    }
    componentData.data = data;
    return componentData;
  }

  private getAnswerFromForm(form, data): string {
    let answer = form.get(data.fieldId).value;
    if (data.controlTypeDesc === this.appConfig.fieldTypes.SELECT && answer) {
      answer = answer.value;
    } else if (data.controlTypeDesc === this.appConfig.fieldTypes.CHECKBOX) {
      answer = answer === true ? 'Yes{47}Yes' : 'No{47}No';
    }
    return answer;
  }
}
